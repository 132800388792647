import useSWR, { mutate, useSWRConfig } from 'swr'
import { createContext, useContext, useMemo } from 'react'
import useAuth from '@crystal-eyes/hooks/useAuth'
import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'
import { DiscType } from '@crystal-eyes/types'

export type EnrichedIdentity = {
  id: string
  isCrystalManaged: boolean
  isCrystalUser: boolean
  isCandidate: boolean
  isCoworker: boolean

  personalInfo: {
    fullName: string
    photoUrl?: string
    relatedUrls: string[]
    jobTitle?: string
    companyName?: string
  }

  snapshot: {
    personality?: { discType: DiscType }
  }

  rootSnapshot: {
    id: string
    source: string
  }
}

export enum State {
  None = 'None',
  Loading = 'Loading',
  Error = 'Error',
  Loaded = 'Loaded',
}

export type EnrichedProfileMetaData = {
  more: boolean
  totalCount: number
}

export type EnrichedIdentities = {
  metadata: EnrichedProfileMetaData
  results: EnrichedIdentity[]
}

export type EnrichedProfilesState = {
  data: { enrichedIdentities: EnrichedIdentities } | undefined
  refetch: () => void
  loading?: boolean
  error?: any
}

export type SortAttributes = {
  direction: 'DESC' | 'ASC'
  field: 'ALPHABETICAL' | 'CREATED_AT' | 'VIEWED_AT'
}

export type FilterAttributes = {
  type:
    | 'DISC_TEXT_TYPE'
    | 'GROUP_ID'
    | 'TRAIT_ID'
    | 'RELATIONSHIP'
    | 'USER_TYPE'
    | 'NAME'
    | 'URL'
    | 'EMAIL'
  value: string
  title?: string
}

export type SortAndFilter = {
  sort: SortAttributes
  filter: FilterAttributes[]
}

export type LimitOffsetPaginationOptions = {
  limit: number
  offset: number
}

export type Options = {
  sort?: SortAttributes
  filters?: FilterAttributes[]
  allowEmptyFilters?: boolean
  page?: LimitOffsetPaginationOptions
}

const ENRICHED_PROFILES_QUERY = gql`
  query EnrichedProfiles(
    $sort: IdentitySort!
    $filters: [EnrichedIdentityFilter]!
    $page: LimitOffsetPaginationOptions!
  ) {
    enrichedIdentities(sort: $sort, page: $page, filters: $filters) {
      metadata {
        more
        totalCount
      }

      results {
        id
        isCrystalManaged
        isCrystalUser
        isCandidate
        isCoworker

        personalInfo(allowCached: true) {
          fullName
          photoUrl
          relatedUrls
          jobTitle
          companyName
        }
        snapshot {
          personality {
            discType
          }
        }
        rootSnapshot {
          id
          source
        }
      }
    }
  }
`

const validQueryFilters = (fullFilters: FilterAttributes[]) => {
  return fullFilters.map(fullFilter => {
    const { type, value } = fullFilter
    return { type, value }
  })
}

const DEFAULT_RELATIONSHIP_FILTERS: FilterAttributes[] = [
  { type: 'RELATIONSHIP', value: 'coworker' },
  { type: 'RELATIONSHIP', value: 'candidate' },
  { type: 'RELATIONSHIP', value: 'team_created' },
]

export const UseEnrichedProfilesContext = createContext<
  EnrichedProfilesState | undefined
>(undefined)

export default function useEnrichedProfiles({
  sort,
  filters,
  allowEmptyFilters,
  page,
}: Options = {}): EnrichedProfilesState {
  const config = useSWRConfig()
  const { data: auth } = useAuth()
  const limitedFilters = useMemo(() => {
    const allFilters = validQueryFilters(filters || [])
    let relFilters = allFilters.filter(f => f.type == 'RELATIONSHIP')
    const nonRelFilter = allFilters.filter(f => f.type != 'RELATIONSHIP')

    if (relFilters.length == 0) {
      relFilters = DEFAULT_RELATIONSHIP_FILTERS
    }

    return [...relFilters, ...nonRelFilter]
  }, [filters])
  const existingCtx = useContext(UseEnrichedProfilesContext)

  const fetcher = ([_key, sort, filters, page]: [
    string,
    SortAttributes,
    FilterAttributes[],
    LimitOffsetPaginationOptions,
  ]) => {
    return queryWithAuth(auth, ENRICHED_PROFILES_QUERY, {
      sort: {
        direction: sort?.direction || 'DESC',
        field: sort?.field || 'CREATED_AT',
      },
      filters: filters,
      page: page || {
        limit: 10,
        offset: 0,
      },
    })
  }

  const doSearch = (filters && filters.length > 0) || allowEmptyFilters
  const {
    data,
    isLoading: loading,
    error,
  } = useSWR(
    auth && doSearch
      ? ['useEnrichedProfiles', sort, limitedFilters, page]
      : null,
    fetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  )

  const refetch = () => {
    for (const key of Array.from(config.cache.keys())) {
      if (key.includes('useEnrichedProfiles')) mutate(key)
    }
  }

  if (existingCtx) return existingCtx
  return {
    data,
    loading,
    error,
    refetch,
  }
}
