export type JwtPayload = {
  exp: number
  sub: string
  name: string
  impersonated_by?: string
}

export type AuthDetails = {
  authed?: boolean
  authToken: string | null | void
  sessionToken?: string | null | void
  appName?: string
  jwtPayload?: JwtPayload
}

export type Organization = {
  id: string
  name: string
}

export enum DiscType {
  Dc = 'Dc',
  D = 'D',
  Di = 'Di',
  DI = 'DI',
  Id = 'Id',
  I = 'I',
  Is = 'Is',
  IS = 'IS',
  Si = 'Si',
  S = 'S',
  Sc = 'Sc',
  SC = 'SC',
  Cs = 'Cs',
  C = 'C',
  Cd = 'Cd',
  CD = 'CD',
}

export enum Archetype {
  Captain = 'Captain',
  Driver = 'Driver',
  Initiator = 'Initiator',
  Influencer = 'Influencer',
  Motivator = 'Motivator',
  Encourager = 'Encourager',
  Harmonizer = 'Harmonizer',
  Counselor = 'Counselor',
  Supporter = 'Supporter',
  Planner = 'Planner',
  Stabilizer = 'Stabilizer',
  Editor = 'Editor',
  Analyst = 'Analyst',
  Skeptic = 'Skeptic',
  Questioner = 'Questioner',
  Architect = 'Architect',
}

export type PersonalInfo = {
  id?: string
  firstName?: string
  lastName?: string
  fullName?: string
  photoUrl?: string | null
  jobTitle?: string
  companyName?: string
  relatedUrls?: string[]
  gender?: string
  linkedinUrl?: string
}

export interface ProfileIdentity {
  id: string
  isCoworker?: boolean
  isCandidate?: boolean
  isCrystalUser?: boolean
  isCrystalManaged?: boolean

  personalInfo?: PersonalInfo

  rootSnapshot?: Snapshot
  snapshot?: Snapshot

  reviewsFromPeers?: {
    peerReviews?: PeerReview[]
    average?: TraitsReview[]
  }

  reviewsOfPeers?: PeerReview[]
}
export interface Identity {
  id: string
  isCoworker?: boolean
  isCandidate?: boolean
  isCrystalUser?: boolean
  isCrystalManaged?: boolean

  personalInfo?: PersonalInfo

  rootSnapshot?: Snapshot
  snapshot?: Snapshot

  reviewsFromPeers?: {
    peerReviews?: PeerReview[]
    average?: TraitsReview[]
  }

  reviewsOfPeers?: PeerReview[]
}

export interface Attendee {
  id: string
  displayName: string
  email: string
  attendeeEnrichedId: string
  organizer: boolean
}

interface IdentityV2 {
  id: string
  personalInfo?: { fullName?: string }
}

export enum PeerReviewStatus {
  PENDING = 'PENDING',
  SHOWN = 'SHOWN',
  HIDDEN = 'HIDDEN',
}

export type PeerReview = {
  id: string
  identity?: IdentityV2
  reviewer?: IdentityV2
  reviewerEmail?: string
  traitsReview: TraitsReview[]
  createdAt?: string
  status: PeerReviewStatus
}

export type TraitReviewName =
  | 'RISK_AVERSE'
  | 'SKEPTICAL'
  | 'PRAGMATIC'
  | 'FAST_PACED'
  | 'EXPRESSIVE'
  | 'COLLABORATIVE'
  | 'DOMINANT'
  | 'risk_averse'
  | 'skeptical'
  | 'pragmatic'
  | 'fast_paced'
  | 'expressive'
  | 'collaborative'
  | 'dominant'

export type TraitsReview = {
  name: TraitReviewName
  score: number
  sum?: number
}

export type Snapshot = {
  id?: string
  personality?: SnapshotPersonality
  vanityUrl?: string
  identity?: Identity
  assessments?: Assessments
  hasBeenEdited?: boolean
  peopleYouMayKnow?: Snapshot[]
  source?: string | null
}

export type SnapshotPersonality = {
  id?: string
  discType?: DiscType | null
  discArchetype?: Archetype | null
  discCircumplex?: CircumplexVector | null
  behaviorOverview?: { id: string; text: string }
  phrases?: {
    id: string
    text: string
  }[]
  sellingOverview?: { id: string; text: string }
  discSignals?: { degrees: number; intensity: number }[]
  predictionDiscSignals?: { degrees: number; intensity: number }[]
  shortOverview?: { id: string; text: string }
  qualities?: { id: string; text: string }[]
  prediction?: {
    discType: DiscType | null
    discCircumplex: CircumplexVector | null
  }
}

export type Personality = SnapshotPersonality

export type Assessments = {
  disc?: DiscAssessmentResults
  bigFive?: BigFiveResults
  enneagram?: EnneagramResults
  mbti?: MbtiResults
  strengths?: StrengthsResults
  values?: ValuesResults
}

export type DiscAssessmentResults = {
  takenAt?: string
}

export type BigFiveResults = {
  agreeableness?: number
  conscientiousness?: number
  extraversion?: number
  emotionalStability?: number
  openness?: number
  takenAt?: string
}

export type MbtiResults = {
  description?: string
  traits: { between: string[]; leansTowards: string }[]
  type?: string
  takenAt?: string
}

export type EnneagramResults = {
  description?: string
  name?: string
  number?: number
  takenAt?: string
}

export type StrengthsResults = {
  traits?: { name: string; description: string }[]
  takenAt?: string
}

export type ValuesResults = {
  traits?: { name: string; description: string }[]
  takenAt?: string
}

export enum DatumTags {
  Drainer = 'DRAINER',
  Energizer = 'ENERGIZER',
  Behavior = 'BEHAVIOR',
  Motivation = 'MOTIVATION',
  Strength = 'STRENGTH',
  BlindSpot = 'BLIND_SPOT',
  Relationship = 'RELATIONSHIP',
  Communication = 'COMMUNICATION',
  Demo = 'DEMO',
  Group = 'GROUP',
  Negotiation = 'NEGOTIATION',
  InPerson = 'IN_PERSON',
  Stress = 'STRESS',
  Dynamic = 'DYNAMIC',
  Sales = 'SALES',
  Team = 'TEAM',

  PhoneCall = 'PHONE_CALL',
  SalesPitch = 'SALES_PITCH',
  GiveFeedback = 'GIVE_FEEDBACK',
  ResolveConflict = 'RESOLVE_CONFLICT',
  Brainstorm = 'BRAINSTORM',
  Collaborate = 'COLLABORATE',
  MakeGoodImpression = 'MAKE_GOOD_IMPRESSION',
  TakeAction = 'TAKE_ACTION',
  Negotiate = 'NEGOTIATE',
  Presentations = 'PRESENTATIONS',
  MakingDecisions = 'MAKING_DECISIONS',
  GatheringInformation = 'GATHERING_INFORMATION',
  SettingExpectation = 'SETTING_EXPECTATIONS',
  BadNews = 'BAD_NEWS',
  GiveInstructions = 'GIVE_INSTRUCTIONS',
  AskRaise = 'ASK_RAISE',
  ConvinceHire = 'CONVINCE_HIRE',
  ThankYou = 'THANK_YOU',
  SayNo = 'SAY_NO',
  LinkedIn = 'LINKEDIN',
  Apologize = 'APOLOGIZE',
  Recruit = 'RECRUIT',
  Introduce = 'INTRODUCE',
  Interview = 'INTERVIEW',

  Discovery = 'DISCOVERY',
  Selling = 'SELLING',
  WorkingTogether = 'WORKING_TOGETHER',
  DiscussPricing = 'DISCUSS_PRICING',
  ScheduleMeeting = 'SCHEDULE_MEETING',
  FollowUp = 'FOLLOW_UP',
  Email = 'EMAIL',

  WorkingWithSubordinate = 'WORKING_WITH_SUBORDINATE',
  WorkingWithPeer = 'WORKING_WITH_PEER',
  WorkingWithManager = 'WORKING_WITH_MANAGER',
}

export enum DatumType {
  Phrase = 'PHRASE',
  Quality = 'QUALITY',
  Overview = 'OVERVIEW',
  Do = 'DO',
  Dont = 'DONT',
  Energizer = 'ENERGIZER',
  Drainer = 'DRAINER',
}

export type PersonalityDatum = {
  id: string
  text: string
  endorsements: Identity[]
  premiumBlur: boolean
  __typename?: string
}

export type MeetingContent = {
  texts: string[]
  premiumBlur: boolean
}

export interface CircumplexVector {
  degrees: number
  intensity: number
}

export type Notification = {
  id: string
  type: NotificationType
  actor?: Identity
}

export enum NotificationType {
  InvitationAccepted = 'INVITATION_ACCEPTED',
  EndorsementReceived = 'ENDORSEMENT_RECEIVED',
  Other = 'OTHER',
  ApprovalReceived = 'APPROVAL_RECEIVED',
  SkillAssessmentTaken = 'SKILL_ASSESSMENT_TAKEN',
}

export enum SearchEngines {
  Celebrities = 'CELEBRITIES',
  TeamCreated = 'TEAM_CREATED',
  Candidates = 'CANDIDATES',
  Teammates = 'TEAMMATES',
  Placeholders = 'PLACEHOLDERS',
  Hubspot = 'HUBSPOT',
}

export enum SearchResultAttribute {
  Candidate = 'CANDIDATE',
  Celebrity = 'CELEBRITY',
  Coworker = 'COWORKER',
  Placeholder = 'PLACEHOLDER',
  TeamCreated = 'TEAM_CREATED',
  Teammate = 'TEAMMATE',
  Verified = 'VERIFIED',
  Hubspot = 'HUBSPOT',
}

export type SearchResult = {
  id: string
  fullName: string
  jobTitle?: string | null
  photoUrl?: string | null
  attributes?: SearchResultAttribute[]
}

export enum PersonalInfoType {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  FullName = 'FULL_NAME',
  JobTitle = 'JOB_TITLE',
  PhotoUrl = 'PHOTO_URL',
  Email = 'EMAIL',
  RelatedUrl = 'RELATED_URL',
}

export enum UsageActionNeeded {
  ContactAdministrators = 'CONTACT_ADMINISTRATORS',
  Upgrade = 'UPGRADE',
}

export type PersonalInfoDatum = {
  type: PersonalInfoType
  value: string
}

export enum ProductAccess {
  Hiring = 'HIRING',
  Sales = 'SALES',
  Leadership = 'LEADERSHIP',
}

export enum PersonalizationTypes {
  JobCandidate = 'JOB_CANDIDATE',
  Customer = 'CUSTOMER',
  Coworker = 'COWORKER',
}

export type ViewerDetails = {
  identity: Identity
  personalizations: PersonalizationTypes[]
  products: ProductAccess[]
  features: string[]
  usageLimits?: {
    allowance: number | null
    usage: number | null
    actionNeeded: UsageActionNeeded | null
  }
  whiteLabelSettings?: { logoUrl?: string }
  isAdmin?: boolean
}

export type EmailsList = { value: string; type: string }[]

export type MeV2 = {
  id: string
  isOrgAdmin: boolean
  actions?: string[]
  identity?: Identity
  personalizations: PersonalizationTypes[]
  products: ProductAccess[]
  features: string[]
  emails?: EmailsList
  createdAt?: Date
  sidenavFeatureFlag?: boolean
  org?: { id: string }
  activeUserIntegrations?: UserIntegrations
  integrations?: UserIntegrations
  usageLimits?: {
    allowance: number | null
    usage: number | null
    actionNeeded: UsageActionNeeded | null
  }
  whiteLabelSettings?: { logoUrl?: string }
}

export type UserIntegrations = {
  googleCalendar?: boolean
  outlookCalendar?: boolean
}

export type PlaybookTemplate = {
  id: string
  name: string
  permalink: string
  icon_url: string
  custom: boolean
  type: string
}

export interface DropdownOption {
  label: string
  value: string | null
}

export type EnrichedAttendee = {
  id: string
  attendeeId: number
  first_name?: string
  last_name?: string
  photo_url?: string
  company_name?: string
  job_title?: string
  email?: string
  qualities?: string[]
  personality?: {
    archetype?: string
    disc_type?: DiscType
    intensity?: number
  }
}

export type MeetingCategory =
  | 'RANDOM'
  | 'INTERNAL_RECURRING'
  | 'INTERVIEW'
  | 'OPERATIONS'
  | 'SALES'
  | 'SOCIAL'
  | 'STRATEGY_AND_PLANNING'

export enum PlaybookTypes {
  Communicating = 'individual-communicating',
  PersonalityComparison = 'relationship-personality-comparison',
  WorkingTogether = 'relationship-working-together',
  Negotiation = 'sales-negotiation',
  NegotiationGroup = 'sales-negotiation-group',
  Brainstorming = 'group-brainstorming-meeting',
  Demo = 'demo',
  DemoGroup = 'demo-group',
  BuildingRaport = 'building-rapport',
  GroupClosing = 'group-closing',
  Selling = 'individual-selling',
}
