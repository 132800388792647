'use client'
import { useCallback, useEffect } from 'react'
import * as ls from 'local-storage'
import { useRouter } from 'next/navigation'
import { publicPaths } from '@dashboard/lib/public_paths'

export default function CheckAuthPages({
  authed = false,
}: {
  authed?: boolean
}) {
  if (authed) return null
  const Router = useRouter()
  const checkAuth = useCallback(() => {
    const isPublicPath = publicPaths.some(path =>
      window.location.pathname.match(new RegExp(path.replace(/\[.*\]/, '.*'))),
    )
    const isWithinApp = window.location.pathname.match(/^\/app\/.*/)
    if (isPublicPath || !isWithinApp) return
    ls.set('afterLoginPath', window.location.pathname + window.location.search)
    Router.push(`/app/login?unauthenticated=true`)
  }, [])

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  return null
}
