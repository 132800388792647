// Load possible configurations
import config_prod from '@crystal-eyes/constants.production'
import config_staging from '@crystal-eyes/constants.staging'
import config_development from '@crystal-eyes/constants.development'
import config_local from '@crystal-eyes/constants.local'
import config_integration from '@crystal-eyes/constants.integration'
import config_test from '@crystal-eyes/constants.test'
export {
  CRYSTAL_AUTH_TOKEN,
  CRYSTAL_SESSION_TOKEN,
} from '@crystal-eyes/lib/constants'

type AppEnv = 'production' | 'staging' | 'development'
const appEnv: AppEnv = (process.env.APP_ENV as AppEnv) || 'development'

const configs: any = {
  production: config_prod,
  staging: config_staging,
  development: config_development,
  local: config_local,
  integration: config_integration,
  test: config_test,
}

if (!configs[appEnv]) {
  throw new Error(`Unknown App Environment: ${appEnv}`)
}

const {
  apiUrl,
  enrichmentApiUrl,
  appUrl,
  devMode,
  hubURL,
  marketingUrl,
  pk_live,
  rudderStackWriteKey,
  useDevtoolsExtension,
  useFullStory,
  useHubspotAnalytics,
  pageTokenName,
} = configs[appEnv]

export const API_URL = process.env.NEXT_PUBLIC_API_URL || apiUrl

const appUrlEnv =
  process.env.NEXT_PUBLIC_APP_URL ||
  (process.env.NEXT_PUBLIC_VERCEL_URL &&
    `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/`)
export const APP_URL = appUrlEnv || appUrl
export const ENRICHMENT_API_URL = enrichmentApiUrl
export const CHROME_INSTALL_URL =
  'https://chrome.google.com/webstore/detail/crystal/nmaonghoefpmlfgaknnboiekjhfpmajh?hl=en'

export const CRYSTAL_PAGE_TOKEN = pageTokenName || 'CRYSTAL_PAGE_TOKEN'
export const DEV_MODE = devMode
export const EDGE_INSTALL_URL =
  'https://microsoftedge.microsoft.com/addons/detail/crystal/jnelhafnadnjckhmlamgkgklddagbpml'
export const GOOGLE_CLIENT_ID =
  '787596633746-uompgl379s03kqkhj8stiph7ebn911ds.apps.googleusercontent.com'
export const HELP_DESK_URL = 'https://docs.crystalknows.com'
export const SALESFORCE_DOCS_SETUP_URL =
  'https://drive.google.com/file/d/1sAE127ydB7bq12Q2ytbZOV4gGLgeiB-H/view'
export const SALESFORCE_INTEGRATION_INSTRUCTIONS_URL =
  'https://docs.crystalknows.com/how-do-i-set-up-the-salesforce-integration-1'
export const HUBSPOT_DOCS_SETUP_URL =
  'https://drive.google.com/file/d/164P5cQ4Eao2KTffjj5vBL9CFWxU2qG5L/view'
export const HUBSPOT_INTEGRATION_INSTRUCTIONS_URL =
  'https://docs.crystalknows.com/how-do-i-set-up-the-hubspot-crm-integration'
export const PARAGON_INTEGRATION_DOCS =
  'https://docs.crystalknows.com/paragon-integration-faqs'
export const HUB_URL = hubURL
export const MARKETING_APP_URL = marketingUrl

export const PK_LIVE = pk_live

export const RUDDER_STACK_WRITE_KEY = rudderStackWriteKey

export const USE_DEVTOOLS_EXTENSION = useDevtoolsExtension
export const USE_FULLSTORY = useFullStory
export const USE_HUBSPOT_ANALYTICS = useHubspotAnalytics

export const LINKEDIN_ME_URL = 'https://www.linkedin.com/in/me/'
export const OUTLOOK_INSTALL_URL =
  'https://crystal-outlook-vsto.s3.amazonaws.com/setup.exe'
export const ZOOM_INSTALL_URL = 'https://zoom.crystl.app/api/zoomapp/install'
export const PARAGON_PROJECT_ID = '14de3eed-e157-44aa-8ddf-4c1eb79804ef'
export const PARAGON_USER_LEVEL_PROJECT_ID =
  '9f8760d2-aed3-4044-a107-d54c6d5baea8'
export const CHROME_EXTENSION_WEB_URL =
  'chrome-extension://nmaonghoefpmlfgaknnboiekjhfpmajh'

export const STATSIG_SERVER_KEY = process.env.STATSIG_SERVER_API_KEY || ''
export const STATSIG_CLIENT_KEY = process.env.STATSIG_CLIENT_API_KEY || ''
export const GMAIL_PARAGON_CALENDAR_ID = 'cfc01bdc-dd5b-4774-a505-88992fc72c13'
export const OUTLOOK_PARAGON_CALENDAR_ID =
  '5939b2f5-914c-4093-bc0d-e011464efd4f'
