export const publicPaths = [
  '/api/.*',
  '/app/login',
  '/app/logout',
  '/app/okta',
  '/why-crystal/.*',
  '/tos',
  '/app/sign-up',
  '/404',
  '/_error',
  '/app/new-assessment',
  '/app/ce/installed',
  '/app/ce/uninstalled',
  '/app/onboarding',
  '/app/hiring/jobs/[jobId]',
  '/app/hiring/jobs/[jobId]/survey',
  '/app/hiring/license',
  '/app/jobs/[jobId]/survey',
  '/app/leadership/license',
  '/app/leadership/playbook-library',
  '/app/password',
  '/app/playbook-library',
  '/app/playbooks/[playbookId]',
  '/app/reactivate',
  '/app/register',
  '/app/sales/license',
  '/app/slack/installed',
  '/app/slack-app',
  '/app/t/[teamId]',
  '/app/upgrade',
  '/app/user-survey',
  '/favicon.ico',
  '/static/.*',
  '/p/.*',
]
